.Nav{
    background-color: #031d60;
    min-height: 48px;
    display: flex;
    align-items: center;
    gap: 32px;
    padding: 0 64px;
    justify-content: space-between;
    font-size: 14px;
    color: white;
    font-weight: 500;
}

.navMain{
    
}

.navItems{
    display: flex;
    align-items: center;
    gap: 32px;
}

.navItem{
    text-transform: uppercase;
}